/*@charset "UTF-8";*/
body {
    background-color: white;
    color: black;
    /*max-width: 1600px;*/
    margin: auto;
}

p {
    line-height: 1.4;
}

article+article {
    margin-top: 3em;
    padding-top: 3em;
    border-top: 1px solid grey;
}

.glavnitext
{
padding:3em;
}
.footer{
   /* position: fixed;*/
    left: 0;
    bottom: 0;
    width: 100%;
}
